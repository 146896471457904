import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import './scss/style.scss';
import Vuex from 'vuex'
import VueAxios from 'vue-axios'
import axios from 'axios';
import VueMatomo from 'vue-matomo';
import VueScrollTo from 'vue-scrollto';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import Notifications from 'vue-notification'
import VueRouterBackButton from 'vue-router-back-button'
// import './scss/style-rtl.scss';


// import "vue-select/dist/vue-select.css";

Vue.config.productionTip = true;
Vue.prototype.modals = true;
export const bus = new Vue({});

/*config*/
/*id of the current app*/
Vue.prototype.$appId = '5';
Vue.prototype.$appName = "Wolfsburger Lupe";
Vue.prototype.$institutionId = '1';
Vue.prototype.$ratgeberId = '12';
Vue.prototype.$kitaId = '14';
Vue.prototype.$schulId = '15';
Vue.prototype.$offerId = '2';
Vue.prototype.$city = 'Wolfsburg';
Vue.prototype.$backendUrl = "https://cms.wolfsburgerlupe.de";
Vue.prototype.$backendApiUrl = "https://cms.wolfsburgerlupe.de/api/";
Vue.prototype.$impressumContentId = 233109;
Vue.prototype.$datenschutzContentId = 233108;
Vue.prototype.$barrierefreiheitsContentId = 233110;
Vue.prototype.$shortDescriptionWordCount = 215;
Vue.prototype.$hideUserAccountRelatedFunctions = true;

Vue.use(Vuex);
Vue.use(VueScrollTo);
Vue.use(Notifications);
Vue.use(VueAxios, axios);
Vue.use(VueRouterBackButton, { router });

/*Initialisieren von Loading*/
Vue.use(Loading, {
  color: 'rgb(148, 192, 31)',
  loader: 'dots',
  width: 140,
  FullPage: true
});

Vue.use(VueMatomo, {
  host: "https://webanalytik.stadt.wolfsburg.de/",
  siteId: 6,
  trackerFileName: 'matomo',
  router: router,
  enableLinkTracking: true,
  requireConsent: false,
  trackInitialView: true,
  disableCookies: false,
  enableHeartBeatTimer: false,
  heartBeatTimerInterval: 15,
  debug: false,
  userId: undefined,
  cookieDomain: undefined,
  domains: undefined,
  preInitActions: []
});

new Vue({
  router,
  store,
  render: function (h) { return h(App) }
}).$mount('#app')
